import "./Agreement.css";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import PdfFile from "./PdfFile";
import axios from "axios";

import { getAgreementUrl, getAgreementFileUrl } from "../Config.jsx";

function Agreement() {
	const { token } = useParams();
	const [found, setFound] = useState(false);
	const [signDate, setSignDate] = useState(0);
	const [phone, setPhone] = useState("");

	async function GetData(url, options) {
		const data = await axios.get(url, {
			params: {
				...(options != null ? options : {}),
			},
			validateStatus: () => true,
		});

		return data.data;
	}

	async function PostData(url, body, options) {
		const data = await axios.post(url, {
			params: {
				...(options != null ? options : {}),
			},

			validateStatus: () => true,
		});

		return data.data;
	}

	const isSigned = () => signDate != null;

	const downloadFile = () => {
		window.open(
			getAgreementFileUrl(token),
			"_blank",
			"noopener,noreferrer"
		);
	};

	const getPdfData = useCallback(async (token) => {
		let data = await GetData(getAgreementUrl(token), null);
		if (data.meta.status === 200) {
			setFound(true);
			setSignDate(data.data.signed);
			setPhone(data.data.customer);
		}
	}, []);

	const signAgreement = async () => {
		let data = await PostData(getAgreementUrl(token));
		if (data.meta.status === 200) {
			await getPdfData(token);
		}
	};

	useEffect(() => {
		getPdfData(token);
	}, [token, getPdfData]);

	return found ? (
		<div id="pdf" className="pdf">
			<PdfFile token={token} />
			<div>
				<p style={{ textAlign: "center" }}>
					{isSigned()
						? `המסמך נחתם בתאריך: ${new Date(
								signDate
						  ).toLocaleDateString("he-IL")}`
						: `בלחיצה על כפתור 'אני מאשר', את\\ה מאשר\\ת שהינך בעל תעודת הזהות ${phone} ומאשר\\ת את תנאי ההסכם.`}
				</p>

				<div
					className="mb-2"
					style={{
						display: "grid",
						gridGap: "10px",
						gridAutoFlow: "column",
					}}
				>
					<Button
						variant="secondary"
						size="lg"
						onClick={downloadFile}
					>
						הורדת קובץ
					</Button>

					<Button
						variant="primary"
						size="lg"
						disabled={isSigned()}
						onClick={async () => {
							await signAgreement();
						}}
					>
						{isSigned() ? "מסמך כבר אושר" : "אני מאשר"}
					</Button>
				</div>
			</div>
		</div>
	) : (
		<div>לא נמצא מסמך הסכם</div>
	);
}

export default Agreement;
