import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfFile = (data) => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		console.log("pdf:", pdfjs.version);
	}

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

	const file = {
		url: `https://docs.service.oversight.co.il/docs/agreements/${data.token}/file`,
		withCredentials: true,
	};

	const widthSize =
		window.visualViewport.width > 550
			? 550
			: window.visualViewport.width * 0.7 - 20;
	return (
		<div>
			<Document
				onLoadError={(err) => console.log(err)}
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				options={{
					disableFontFace: true,
					standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
				}}
			>
				<Page
					pageNumber={pageNumber}
					renderTextLayer={false}
					renderAnnotationLayer={false}
					width={widthSize}
					scale={1.5}
				/>
			</Document>

			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button variant="link" size="sm" onClick={goToPrevPage}>
					<FaArrowRight />
				</Button>
				<Button
					variant="link"
					size="md"
					disabled
					onClick={goToPrevPage}
				>
					{pageNumber}/{numPages}
				</Button>
				<Button variant="link" size="sm" onClick={goToNextPage}>
					<FaArrowLeft />
				</Button>
			</div>
		</div>
	);
};

export default PdfFile;
