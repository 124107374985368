import './App.css';

import React, { Component } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Agreement from './Agreement/Agreement';

class App extends Component {
  render() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:token" element={<Agreement/>}/>
            </Routes>
        </BrowserRouter>
    );
  }
}
export default App;